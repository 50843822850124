<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="listProducts"
      :loading="isLoadingProducts"
      :server-items-length="productsMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [5, 30, 50] }"
    >
      <template v-slot:item.image="{ item }">
        <v-img
          v-if="item.image"
          :src="item.image.url"
          max-height="50"
          max-width="50"
        />
        <v-img
          v-else
          src="@/assets/product.png"
          max-height="50"
          max-width="50"
        />
      </template>
      <template v-slot:item.express_status="{ item }">
        <v-chip :color="getColor(item.express_status)" dark>
          {{ item.express_status ? $t("active") : $t("inactive") }}
        </v-chip>
      </template>
      <template v-slot:item.reserved_qte="{ item }">
        <v-btn
          text
          plain
          large
          @click="showOrders(item)"
          v-if="item.reserved_qte > 0"
        >
          {{ item.reserved_qte }}
        </v-btn>
      </template>
      <template v-slot:item.qte_in_hub="{ item }">
        <span :class="getQteColor(item)">{{ item.qte_in_hub }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="showItem(item)"> mdi-eye </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("products") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            v-if="$admin.can('product-export')"
            color="primary"
            @click="getCSV(options)"
            :loading="is_loading_csv"
            :disabled="is_loading_csv"
          >
            <v-icon color="white" class="mr-2">
              mdi-arrow-down-bold-circle-outline
            </v-icon>
            {{ $t("export_result_csv") }}
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="options.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              clearable
              v-model="options.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="isLoadingHubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              clearable
              v-model="options.brand_id"
              :items="brands"
              item-text="name"
              item-value="id"
              :loading="isLoadingBrands"
              :label="$t('brand')"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3">
            <v-autocomplete
              clearable
              v-model="options.category_id"
              :items="categories"
              item-text="name"
              item-value="id"
              :loading="isLoadingCategories"
              :label="$t('category')"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <show-product></show-product>
    <show-orders
      v-if="isOrderModalOpen"
      :isModalOpen="isOrderModalOpen"
      :currentItem="currentItem"
      :toggleModal="() => toggleOrdersModal()"
    ></show-orders>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import stockService from "@/store/services/stock-service";

export default {
  components: {
    ShowProduct: () => import("../ShowProductModal.vue"),
    ShowOrders: () => import("../ShowOrdersModal.vue"),
  },

  computed: {
    ...mapGetters({
      isLoadingCategories: "categories/isLoadingCategories",
      isLoadingBrands: "brands/isLoadingBrands",
      isLoadingHubs: "hubs/isLoadingHubs",
      isLoadingSections: "sections/isLoadingSections",
      isLoadingAttributes: "attributes/isLoadingAttributes",

      hubs: "hubs/listHubs",
      brands: "brands/listBrands",
      stores: "stores/listStores",
      categories: "categories/listCategories",

      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",
    }),
  },

  watch: {
    options: {
      handler: debounce(async function () {
        this.is_loading_product = true;
        await this.$store.dispatch("products/root", this.options).then(() => {
          this.is_loading_product = false;
          if (this.options.page > (this.productsMeta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
      }, 1000),
      deep: true,
    },

    search_hub: debounce(function (search) {
      if (this.isLoadingHubs) return;

      this.$store.dispatch("hubs/list", {
        store_id: this.store_id,
        itemsPerPage: 30,
        search,
      });
    }, 1000),
  },

  data() {
    return {
      options: { itemsPerPage: 30 },
      search_hub: "",
      is_loading_product: false,
      is_loading_csv: false,
      currentItem: {},
      isOrderModalOpen: false,

      headers: [
        {
          text: this.$t("sku"),
          align: "start",
          sortable: true,
          value: "sku",
        },
        {
          text: this.$t("product_n"),
          align: "start",
          sortable: true,
          value: "code",
        },
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("quantity"),
          value: "qte_in_hub",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("reserved"),
          value: "reserved_qte",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("express_status"),
          value: "express_status",
        },
        {
          text: this.$t("price"),
          value: "price",
        },
        {
          text: this.$t("created_at"),
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    showItem(item) {
      this.$store.dispatch("products/openShowForm", item);
    },

    async showOrders(item) {
      try {
        const data = await stockService.listReservedQuantityOrders({
          product_id: item.id,
          hub_id: item.hub_id,
        });

        this.currentItem = { ...item, orders: data.orders };
        console.log(this.currentItem);
        this.toggleOrdersModal();
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    toggleOrdersModal() {
      this.isOrderModalOpen = !this.isOrderModalOpen;
    },

    getColor(status) {
      if (status === "active" || status == 1) return "green";
      else return "red";
    },

    getQteColor(item) {
      if (item.qte_in_hub == item.min_qte_in_hub)
        return "orang--text text--darken-3";
      else if (item.qte_in_hub < item.min_qte_in_hub)
        return "red--text text--darken-3";
      else return "";
    },

    async getCSV(option) {
      this.is_loading_csv = true;
      option.with_hubs = true;

      await this.$store
        .dispatch("products/downloadCSV", option)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "products.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.is_loading_csv = false;
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        });
    },
  },
};
</script>
