import axios from "@/helpers/axios";
import authService from "@/store/services/auth-service";

const base_url = process.env.VUE_APP_API_BASE_URL;

async function listReservedQuantityOrders(params) {
  return axios
    .get(
      `${base_url}stocks/reserved-quantity/${params.product_id}/${params.hub_id}/orders`,
      { headers: authService.authHeader() }
    )
    .then((response) => {
      return response.data;
    });
}

export default {
  listReservedQuantityOrders,
};
